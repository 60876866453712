import Wheel from './modules/Wheel';
import Parallax from './modules/Parallax';
import Button from './modules/Button';

class App {
    
    constructor(options) {
        this.options = options || {};

        this.node = {
            gameButton: document.querySelector('.js-game-button'),
            targetButton: document.querySelector('.js-target-button'),
            screens: document.querySelectorAll('.js-screengame'),
            bodyWrap: document.querySelector('.js-body-wrap'),
            wheelWrap: document.querySelector('.js-wheel-wrap'),
            personWrap: document.querySelector('.js-person-wrap'),
            page: document.querySelector('.js-bg-wrapper'),
            bgLayers: document.querySelectorAll('.js-bg-layer'),
            counter: document.querySelector('.js-game-counter')
        };

        this.class = {
            wheelContainerCentered: 'container__wheel--centered',
            personContainerOffset: 'container__person--offset',
            bodyContainerShow: 'container__body--show',
            winControlHide: 'win-controls__item--hide',
            backgroundOffset: 'page__bg--offset',
            screengameShow: 'screengame--show',
            buttonTada: 'button--tada'
        };

        this.state = {
            currentGame: 0,
            spinCounter: 3,
            activeGame: false,
            firstGame: true,
        };

        this.firstGameTimeout = 2000;
        this.screenPlay = [1818, 1486, 1667];
        this.wheel = new Wheel();

        this.init();
    }

    init() {
        this.node.gameButton.addEventListener('click', (e) => this.startGame());

        window.addEventListener('DOMContentLoaded', () => {
            setTimeout(() => this.startGame(), this.firstGameTimeout);
        
            new Parallax({
                wrapper: '.js-bg-wrapper',
                layers: '.js-bg-layer',
                deep: 'data-parallax-deep'
            });
        
            new Button({
                button: '.js-button-mouse-tracking',
                shadow: '.js-button-mouse-tracking-shadow'
            });
        });
        
        window.addEventListener('wheelGame', (e) => this.gameHandler(e));
    }

    startGame() {
        if (!this.state.activeGame) {
            this.state.activeGame = true;
            this.node.gameButton.disabled = true;

            if (this.state.spinCounter !== 0) {
                let deg = this.screenPlay[this.state.currentGame];

                this.wheel.rotate(deg);

                this.state.spinCounter--;
                this.state.currentGame++;
            }
        }
    }

    endGame() {
        this.state.activeGame = false;
        this.node.gameButton.disabled = false;

        if (this.state.firstGame) {
            this.node.wheelWrap.classList.remove(this.class.wheelContainerCentered);
            this.node.personWrap.classList.remove(this.class.personContainerOffset);
    
            this.node.bgLayers.forEach((e) => {
                e.classList.remove(this.class.backgroundOffset);
            });
        }

        setTimeout(() => {
            setTimeout(() => {
                this.showScreenGame(this.state.currentGame);
            }, 100);

            this.node.bodyWrap.classList.add(this.class.bodyContainerShow);
        }, 1500);

        this.node.counter.innerHTML = this.state.spinCounter;

        if (this.state.spinCounter === 0) {
            this.node.gameButton.parentNode.classList.add(this.class.winControlHide);

            setTimeout(() => {
                this.node.targetButton.classList.add(this.class.buttonTada);
            }, 3000);
        }
    }

    showScreenGame(id) {
        this.node.screens.forEach((screen) => {

            if (parseInt(screen.dataset.screenplay) === id) {
                screen.classList.add(this.class.screengameShow);
                screen.style.maxHeight = screen.children[0].scrollHeight + 'px';
            }
        });
    }

    gameHandler(event) {

        switch (event.detail.status) {
            case 'begin':
                this.startGame();
                break;
            case 'complete':
                this.endGame();
                break;
            case 'rotation':
                console.log('The wheel rotates');
                break;
        }
    }
}

new App();