class Parallax {

    constructor(options) {
        this.options = options || {};

        this.namespace = {
            wrapper: this.options.wrapper || '.parallax',
            layer: this.options.layers || '.parallax-layer',
            deep: this.options.deep || 'data-parallax-deep'
        };

        this.init();
    }

    init() {
        let wrappers = document.querySelectorAll(this.namespace.wrapper);

        wrappers.forEach((wrapper) => {
            let layers = wrapper.querySelectorAll(this.namespace.layer);

            wrapper.onmousemove = (e) => {
                let x = e.clientX;
                let y = e.clientY;
                
                layers.forEach((layer) => {
                    let deep = layer.getAttribute(this.namespace.deep);
                    let disallow = layer.getAttribute('data-parallax-disallow');

                    let itemX = (disallow && disallow === 'x') ? 0 : x / deep;
                    let itemY = (disallow && disallow === 'y') ? 0 : y / deep;

                    if (disallow && disallow === 'both') return;

                    layer.style.transform = 'translateX(' + itemX + '%) translateY(' + itemY + '%)';
                });
            };
        });
    }
}

export default Parallax;