import anime from 'animejs';

class Wheel {

    constructor() {
        this.state = {
            clickerAngle: 0,
            prevClickerAngle: 0,
            wheelAngle: 0,
            sectorTick: 1,
            isRotated: false,
            enabledSound: true,
            wheelProgress: 0,
            meta: {
                wheelAngle: 0
            }
        };

        this.node = {
            wheel: document.querySelector('.wheel'),
            clicker: document.querySelector('.wheel-clicker'),
            highlighter: document.querySelector('.wheel-container__highlighter'),
            sectors: document.querySelectorAll('.wheel-sector')
        };

        this.class = {
            showHighlighter: 'wheel-container__highlighter--show',
            sectorHightlight: 'wheel-sector--highlight'
        };

        this.sound = {
            wheel: document.getElementById('sound-wheel'),
            fanfar: document.getElementById('sound-fanfar')
        };

        this.sectors = 10;
        this.sectorAngle = 360 / this.sectors;
        this.cubicBezier = '.34, .01, .31, .97';
        this.duration = 9000; // 9
        this.ticker;

        this.init();
    }

    init() {
        this.sound.fanfar.volume = 0.5;
        this.sound.wheel.volume = 0.3;
    }

    updateClickerAngle() {
        let metaAngle = this.state.meta.wheelAngle;
        let newWheelAngle = Math.abs(metaAngle);

        newWheelAngle += this.sectorAngle / 2 + 4;

        if (this.wheelProgress > 20 && this.wheelProgress < 40) {
            this.node.clicker.style.transition = 'transform ' + 1500 + 'ms cubic-bezier(' + this.cubicBezier + ')';
            this.state.clickerAngle = -40;
        } if (newWheelAngle > this.state.wheelAngle) {
            /* right */
            this.node.clicker.style.transition = '';
            this.state.clickerAngle = this.sectorAngle - (newWheelAngle % this.sectorAngle) * 14 - 15;
            this.state.clickerAngle = (this.state.clickerAngle > 0) ? 0 : this.state.clickerAngle;
        } else if (newWheelAngle < this.state.wheelAngle) {
            /* left */
            this.node.clicker.style.transition = '';
            this.state.clickerAngle = this.sectorAngle - (newWheelAngle % this.sectorAngle) * 14 + 50;
            this.state.clickerAngle = (this.state.clickerAngle < 0) ? 0 : this.state.clickerAngle;
        }

        const clickerLimitAngle = 50;

        if (Math.abs(this.state.clickerAngle) > clickerLimitAngle) {
            this.state.clickerAngle = 0;
        }

        /* TODO: add sector offset for sound */

        if (this.state.meta.wheelAngle / this.state.sectorTick > this.sectorAngle) {
            this.playSound('wheel');
            this.state.sectorTick++;
        }

        this.node.clicker.style.transform = 'rotate(' + this.state.clickerAngle + 'deg)';
        this.state.wheelAngle = newWheelAngle;
        this.state.prevClickerAngle = this.state.clickerAngle;
    }

    playSound(slug) {
        let promise;

        switch (slug) {
            case 'wheel':
                this.sound.wheel.pause();
                this.sound.wheel.currentTime = 0;
                promise = this.sound.wheel.play();
                break;
            case 'fanfar':
                this.sound.fanfar.pause();
                this.sound.fanfar.currentTime = 0;
                promise = this.sound.fanfar.play();
                break;
        }

        if (promise !== undefined) {
            promise.catch(error => {
                console.log('Play sound (' + slug + ') error: ' + error);
            });
        }
    }

    sendEvent(detail) {
        let event = new CustomEvent('wheelGame', {
            detail: detail
        });

        window.dispatchEvent(event);
    }

    rotateWheel(deg, duration) {
        this.node.wheel.style.transition = 'transform ' + duration + 'ms cubic-bezier(' + this.cubicBezier + ')';
        this.node.wheel.style.transform = 'rotate(' + deg + 'deg)';
    }

    rotateBegin() {
        this.node.highlighter.classList.remove(this.class.showHighlighter);
        this.ticker = setInterval(this.updateClickerAngle.bind(this), 10);
        this.state.isRotated = true;
        
        try {
            document.querySelector('.' + this.class.sectorHightlight).classList.remove(this.class.sectorHightlight);
        } catch (e) { }

        this.sendEvent({
            status: 'begin'
        });
    }

    rotateComplete() {
        clearInterval(this.ticker);

        setTimeout(
            this.rotateFinishing.bind(this)
        , 500);

        this.updateClickerAngle();
    }

    rotateFinishing() {
        let a = this.state.wheelAngle / this.sectorAngle; // passedSectors
        let b = Math.floor(a) - .49;
        let c = Math.floor(this.state.wheelAngle + this.sectorAngle * 7  / this.sectorAngle);

        while (c > this.sectors) {
            c = c - this.sectors;
        }

        const offsetTimeout = 1000;

        this.state.wheelAngle = this.sectorAngle * b;
        this.state.meta.wheelAngle = this.state.wheelAngle;

        this.rotateWheel(this.state.wheelAngle, offsetTimeout);
        this.updateClickerAngle();

        this.node.clicker.style.transition = 'transform ' + offsetTimeout + 'ms cubic-bezier(' + this.cubicBezier + ')';

        function highlight() {
            this.node.highlighter.classList.add(this.class.showHighlighter);
            this.node.sectors[c - 1].classList.add(this.class.sectorHightlight);

            this.playSound('fanfar');
        }

        setTimeout(highlight.bind(this), offsetTimeout + 500);

        this.state.isRotated = false;

        this.sendEvent({
            status: 'complete'
        });
    }

    rotate(deg) {
        if (!this.state.isRotated) {
            this.state.wheelAngle += deg;

            this.rotateWheel(this.state.wheelAngle, this.duration);

            let amineOptions = {
                targets: this.state.meta,
                duration: this.duration,
                easing: 'cubicBezier(' + this.cubicBezier + ')',
                wheelAngle: this.state.wheelAngle,
                update: (anim) => this.wheelProgress = anim.progress,
                begin: () => this.rotateBegin(),
                complete: () => this.rotateComplete()
            };

            anime(amineOptions);
        } else {
            this.sendEvent({
                status: 'rotation'
            });  
        }
    }
}

export default Wheel;