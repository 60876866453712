class Button {

    constructor(options) {
        this.options = options || {};

        this.namespace = {
            button: this.options.button || '.button-mouse-tracking',
            shadow: this.options.shadow || '.button-mouse-tracking-shadow'
        };

        this.init();
    }

    init() {
        const buttons = document.querySelectorAll(this.namespace.button);

        buttons.forEach((button) => {
            const shadow = button.querySelector(this.namespace.shadow);

            button.onmousemove = (e) => {
                let rect = button.getBoundingClientRect();
            
                let x = e.pageX - rect.left;
                let y = e.pageY - rect.top;
                
                shadow.style.left = x + 'px';
                shadow.style.top = y + 'px';
            };
        });
    };
}

export default Button;